import { Reference } from "../reference-interface"
import { opInfoCards } from "../../service-info-card-interface/used_by/elitsafety-cards"

export const ElitSafety : Reference = {
  monitorPic: "/images/imac_elit_template.png",
  siteName: "Elit Safety webshop",
  siteDescription: "Az Elit Safety a professzionális maszkok webáruháza. Az Elit Safety azzal keresett meg minket, hogy szeretnének minél gyorsabban az online értékesítés felé nyitni.",
  siteUrl: "https://www.elitsafety.hu/",
  cards: opInfoCards,
  referencePics: [
    "/images/refs/elit/elit_1.jpg",
    "/images/refs/elit/ref.png",
    "/images/refs/elit/elit_2.jpg",
  ],
  pageFunctions: [
    "Saját ERP",
    "Egyedi design",
    "Önálló adminfelület",
    "Kis- és nagykernek egyaránt",
    "Ügyfélszolgálat",
    "Egyedi partner árak",
  ],
  reversePageFunctions: false,
}
