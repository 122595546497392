import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const op1 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Minél gyorsabban az online értékesítés felé nyitnának, így egy kevésbé komplex, de mégis egyedi fejlesztésű webshopra esett a döntés. ",
  displayXo: true,
  reverse: false,
  title: "Professzionális maszkok",
  picture: "/images/refs/elit/ref_color.png"
}

const op2 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Különböző terméktípusokat kombináltunk, hogy egyedi\n" +
    "termékajánlatokat hozzunk létre. A felhasználók online viselkedésének nyomon követését üzleti elemzőink és UX szakértőink is segítették.\n" +
    "Ezáltal egy innovatív rendszert alakítottunk ki.",
  displayXo: true,
  reverse: true,
  title: "Gyors piacralépés",
  picture: "/images/refs/elit/elit_2.png"
}

export const opInfoCards = [
  op1,
  op2
]

